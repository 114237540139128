/* eslint-disable @typescript-eslint/no-empty-function */
import { Box, useMediaQuery, useTheme } from "@mui/material"
import { Open_Sans } from "@next/font/google"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import Router from "next/router"
import NProgress from "nprogress" //nprogress module
import "nprogress/nprogress.css" //styles of nprogress
import React, { useEffect } from "react"
import CouponsDetailsModal from "../components/coupons/CouponsDetailsModal"
import PrimaryLayout from "../components/layout/PrimaryLayout"
import LoginRequestModal from "../components/ui/loginRequestModal/LoginRequestModal"
import Snackbar from "../components/ui/snackbar/Snackbar.tsx"
import "../components/ui/SwipeableTextMobileStepper.css"
import { usePreviousRoute } from "../components/usePreviousRoute"
import generateConfig from "../configs/config"
import { useAuth } from "../contexts/AuthContext"
import Wrapper from "../contexts/Wrapper"
import "../styles/globals.scss"
import Script from "next/script"
import ProductDetailsModal from "../components/product/ProductDetailsModal"
import ReactGA from "react-ga4"
import { EventName, gaLogEvent } from "../utils/googleAnalyticsEvents"
import AddPaypalModal from "../components/accounts/coupons/RebateCashback/AddPaypalModal"
import { getWebInstrumentations, initializeFaro } from "@grafana/faro-web-sdk"
import { faroPushEvent } from "../utils/faroGrafanaLogs"
import Cookies from "js-cookie"
import { useState } from "react"
// import { TracingInstrumentation } from "@grafana/faro-web-tracing"

const config = generateConfig()

if (config.enableMocks === "true") {
  require("../mocks")
}

// we do not need to initialize through this plugin, as we are using two different data streams, and this plugin does not allow us to initialize twice -- also events and things will work and have been tested, so it is ok even though it is not initialized
// ReactGA.initialize(process.env.NEXT_PUBLIC_GA4)
// ReactGA.initialize(process.env.NEXT_PUBLIC_GA4_CLIENT)

const openSans = Open_Sans({ subsets: ["latin"] })

// This setups all the client-data-bom calls for GraphQL
// we do this in auth context now
// ApiSetup.init()

// Spinner on route changes
NProgress.configure({
  easing: "ease",
  speed: 800,
  showSpinner: false,
  parent: "#progressbar",
})
Router.events.on("routeChangeStart", () => NProgress.start())
Router.events.on("routeChangeComplete", () => NProgress.done())
Router.events.on("routeChangeError", () => NProgress.done())

// INFO: Add/Update App level Providers/Themes/Global Fonts/Global Styles/Services inside Wrapper Component
// so it would also be added in Cypress Component Tests

function MyApp({ Component, emotionCache, pageProps }) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => page)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(700))
  const previousRoute = usePreviousRoute()
  const [showGAandGTM, setShowGAandGTM] = useState(false)
  let access_token = ""
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { authenticate } = useAuth()

  useEffect(() => {
    config.grafanaEnabled &&
      initializeFaro({
        url: `https://faro-collector-prod-us-east-0.grafana.net/collect/${config.faro_id}`,
        app: {
          name: config.configBanner,
          version: "1.0.0",
          environment: "production",
        },
        instrumentations: [...getWebInstrumentations()],
      })
  }, [])

  useEffect(() => {
    localStorage.setItem("getPreviousUrl", previousRoute)
    if (previousRoute && previousRoute.indexOf("access_token") > -1) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      access_token = previousRoute.split("#access_token=")[1].split("&")[0]
      localStorage.setItem("access_token", access_token)
    } else {
    }
  }, [previousRoute])

  useEffect(() => {
    let previousCookieValue = Cookies.get("cmapi_cookie_privacy")

    const checkCookieChange = () => {
      const currentCookieValue = Cookies.get("cmapi_cookie_privacy")
      if (currentCookieValue && currentCookieValue.includes("2")) {
        setShowGAandGTM(true)
      }
      if (currentCookieValue !== previousCookieValue) {
        previousCookieValue = currentCookieValue

        if (currentCookieValue && !currentCookieValue.includes("2")) {
          setShowGAandGTM(false)

          const gtmScript = document.getElementById("googleTagManager")
          if (gtmScript) {
            gtmScript.parentNode.removeChild(gtmScript)
          }

          const gaScript = document.getElementById("ga4")
          if (gaScript) {
            gaScript.parentNode.removeChild(gaScript)
          }

          const scripts = Array.from(document.getElementsByTagName("script"))
          scripts.forEach((script) => {
            if (script.src.includes("googletagmanager")) {
              script.parentNode.removeChild(script)
            }
          })
        } else {
          setShowGAandGTM(true)
        }
        window.location.reload()
      }
    }

    const intervalId = setInterval(checkCookieChange, 1000)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=2.0; user-scalable=1"
      />
      {showGAandGTM && (
        <>
          {/* Google Tag Manager */}
          <Script
            id="googleTagManager"
            dangerouslySetInnerHTML={{
              __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_TAG}');
              `,
            }}
          />

          {/* Google Tag Manager (noscript) */}
          <noscript
            dangerouslySetInnerHTML={{
              __html: `
                <iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_TAG}"
                height="0" width="0" style="display:none;visibility:hidden"></iframe>
              `,
            }}
          />
          <Script
            async
            strategy="lazyOnload"
            src={`https://www.googletagmanager.com/gtag/js?id=${config.ga4}`}
          />
          <Script id="ga4" strategy="afterInteractive">
            {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${config.ga4}')
                  gtag('config', '${config.ga4_client}')
                  `}
          </Script>
          <Script
            async
            strategy="lazyOnload"
            src={`https://www.googletagmanager.com/gtag/js?id=${config.ga4}`}
          />
          {/* <Script id="ze-snippet" src={config.chatScript}></Script>
          <Script
            id="zdn_cht"
            dangerouslySetInnerHTML={{
              __html: `
              window.zESettings = {
                webWidget: {
                  color: { theme : '#241E1E' },
                  launcher: {
                    chatLabel: {
                    '*': 'Need Help?'
                    }
                    }    }
              };`,
            }}
            onClick={() => {
              gaLogEvent({
                eventName: EventName.chat_open,
                parameters: {
                  item_location: window.location.pathname,
                },
              })
              faroPushEvent(EventName.chat_open)
            }}
          /> */}

          <Script id="servicenow" type="module">
            {`
              import ServiceNowChat from "https://tsmcdev.service-now.com/uxasset/externals/now-requestor-chat-popover-app/index.jsdbx?sysparm_substitute=false";
                const chat = new ServiceNowChat({
                instance: "https://tsmcdev.service-now.com",
                context: {
                  skip_load_history: 1
                },
                branding: {
                  bgColor: '#241E1E',
                  primaryColor: '#241E1E',
                  hoverColor: '#EFEFEF',
                  activeColor: '#AAA',
                },
                offsetY: 20,
                offsetX: 20,
                position: 'right',
                translations: {
                  'Open dialog': 'Open chat',
                  'Open Chat. {0} unread message(s)': 'Click to open',
                  'Close chat.': 'Click to close',
                },
              });
            `}
          </Script>
        </>
      )}
      {!!config.breinifyUrl && (
        <Script crossorigin="anonymous" src={config.breinifyUrl} />
      )}
      <div
        id="progressbar"
        style={{
          padding: "2px",
          position: "fixed",
          width: "100%",
          zIndex: "999999",
        }}
      ></div>
      {showGAandGTM && (
        // eslint-disable-next-line @next/next/no-img-element, jsx-a11y/alt-text
        <img
          src="https://d21y75miwcfqoq.cloudfront.net/fee5e7a1"
          aria-hidden="true"
        />
      )}
      <ErrorBoundary>
        <Wrapper
          pageProps={pageProps}
          emotionCache={emotionCache}
          // this component can be viewed by authenticated users only (optional)
          requiresAuth={!!Component.requiresAuth}
          // if page is accessed by already logged in user then redirect to other route (optional e.g. /accounts)
          redirectAuthenticatedTo={Component.redirectAuthenticatedTo}
          // if user is not authorized redirect to other route (optional e.g. /login or /register)
          redirectUnauthenticatedTo={Component.redirectUnauthenticatedTo}
        >
          <LoginRequestModal />
          <CouponsDetailsModal />
          <ProductDetailsModal />
          <AddPaypalModal />
          <Snackbar />
          <div id="consent_blackbar"></div>
          <Script
            async="async"
            src={`//consent.trustarc.com/notice?domain=${config.siteName}.com&c=teconsent&js=nj&noticeType=bb&text=true&gtm=1&irmc=irmlink`}
            crossOrigin="anonymous"
            strategy="afterInteractive"
          ></Script>
          <Box className={openSans.className} data-cy={`is-mobile-${isMobile}`}>
            <PrimaryLayout>
              {getLayout(<Component {...pageProps} />)}
            </PrimaryLayout>
          </Box>
          <ReactQueryDevtools initialIsOpen={false} />
        </Wrapper>
      </ErrorBoundary>
    </>
  )
}

const errorMessage =
  "Sorry, we are experiencing some technical difficulties. Please try again later"
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)

    this.state = { hasError: false }
  }
  static getDerivedStateFromError(error) {
    return { hasError: true }
  }
  componentDidCatch(error, errorInfo) {}
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h2>{errorMessage}</h2>
        </div>
      )
    }

    // Return children components in case of no error

    return this.props.children
  }
}

export default MyApp
